var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-after-check"},[_c('div',{staticClass:"contentDiv"},[_c('el-table',{ref:"multipleTable",attrs:{"border":"","height":"800","data":_vm.tableData,"tooltip-effect":"dark"}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}),_c('el-table-column',{attrs:{"prop":"hd_id","label":"客户id","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"after_item","label":"申请订单号","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"quantity","label":"商品数","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"label":"申请金额","prop":"total"}}),_c('el-table-column',{attrs:{"label":"审核金额","prop":"after_total_money"}}),_c('el-table-column',{attrs:{"label":"售后原因","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.order_pack_type === 0)?_c('i',[_vm._v("质量问题")]):_vm._e(),(row.order_pack_type === 1)?_c('i',[_vm._v("物流破损")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"申请时间","align":"center","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDateStr")(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"订单状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('span',[_vm._v("待审核")]):_vm._e(),(row.status === 1)?_c('span',[_vm._v("部分通过")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("全部通过")]):_vm._e(),(row.status === 3)?_c('span',[_vm._v("全部拒绝")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"当前审核节点"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.approval_status === 1)?_c('span',[_vm._v("客服审核")]):_vm._e(),(row.approval_status === 2)?_c('span',[_vm._v("A岗审核")]):_vm._e(),(row.approval_status === 3)?_c('span',[_vm._v("营销中心负责人审核")]):_vm._e(),(row.approval_status === 4)?_c('span',[_vm._v("总裁审核")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleGetOrderAfterInfo(row)}}},[_vm._v("审批 ")])]}}])})],1)],1),_c('el-dialog',{attrs:{"visible":_vm.drawerStatus.drawer,"width":"60%"},on:{"update:visible":function($event){return _vm.$set(_vm.drawerStatus, "drawer", $event)}}},[_c('div',[_c('el-form',{attrs:{"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{attrs:{"type":"textarea","row":8},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1),_c('el-form-item',{attrs:{"label":""}},[_c('el-button',{attrs:{"type":"success","plain":""},on:{"click":function($event){return _vm.handleCheckOrderAfter(2)}}},[_vm._v("审核通过")]),_c('el-button',{attrs:{"type":"warning","plain":""},on:{"click":function($event){return _vm.handleCheckOrderAfter(3)}}},[_vm._v("审核拒绝")])],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.afterSaleInfo.OrderGoodsAfter,"height":"500","border":""}},[_c('el-table-column',{attrs:{"prop":"goods_code","label":"条码","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"name","label":"图片","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_presell ===1)?_c('el-image',{attrs:{"height":"60","src":row.presell_goods_img,"preview-src-list":[row.presell_goods_img],"z-index":9999,"alt":""}}):_c('el-image',{attrs:{"height":"60","src":("http://hwimg.xmvogue.com/thumb/" + (row.goods_code) + ".jpg?x-oss-process=style/440"),"preview-src-list":[("http://hwimg.xmvogue.com/thumb/" + (row.goods_code) + ".jpg?x-oss-process=style/440")],"z-index":9999,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"num","label":"数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"price","label":"单价","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"label":"总价","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s((row.num * (row.price * 1000)) / 1000)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"reason","label":"理由","align":"center"}}),_c('el-table-column',{attrs:{"prop":"address","label":"上传面单","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.imagesArr),function(item,index){return _c('el-image',{key:index,attrs:{"src":item,"height":"60","z-index":9999,"preview-src-list":[item],"alt":""}})})}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"商品图片","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.goodsImgArr),function(item,index){return _c('el-image',{key:index,attrs:{"src":item,"height":"60","z-index":9999,"preview-src-list":[item],"alt":""}})})}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('span',[_vm._v("待审核")]):_vm._e(),(row.status === 1)?_c('span',[_vm._v("拒绝")]):_vm._e(),(row.status === 2)?_c('span',[_vm._v("同意")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status > 0)?_c('p',[_vm._v("已处理")]):_vm._e(),(row.status === 2)?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.setAfterGoodsStatus(row, 1)}}},[_vm._v("拒绝")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }